import React from 'react';
import styled from 'react-emotion';
import BenefitItem, { BenefitItems } from '../../components/BenefitItem';
import cogoSearchPath from '../../images/cogo_search.png';
import cogoTagsPath from '../../images/cogo_tags.png';
import cogoNoMoreFolders from '../../images/cogo_no_more_folders.png';
import cogoPresentationPath from '../../images/cogo_presentation.png';
import cogoAnnouncements from '../../images/cogo_notification.png';
import cogoVersioningPath from '../../images/cogo_versioning.png';
import googledriveLogoPath from '../../images/googledrive_logo_svg.svg';
import slackLogoPath from '../../images/Slack_RGB.svg';
import dropboxLogoPath from '../../images/dropbox_logo_svg.svg';
import onedriveLogoPath from '../../images/onedrive_logo_svg.svg';
import qlikLogo from '../../images/qlik-logo.svg';
import microstrategyLogo from '../../images/microstrategy-logo.svg';
import lookerLogo from '../../images/looker-logo.svg';
import sapLogo from '../../images/sap-logo.svg';
import boxLogoPath from '../../images/box_logo_svg.svg';
import tableauLogoPath from '../../images/tableau-logo.svg';
import goodDataLogoPath from '../../images/good-data.svg';
import powerbiLogoPath from '../../images/powerbi.svg';
import { IntegrationsImg } from '../../components/Images';
import { colors } from '../../components/styles';
import { H4Text, H3, Highlight } from '../../components/Typography';
import OutlineButton from '../../components/OutlineButton';
import CappedWidth from '../../components/CappedWidth';
import { FullHeightTable, InnerContent } from './common';
import { GridTopCol } from '../../components/grid/Item';

const InnerWrapper = styled.div`
  display: table;
  clear: both;
  content: '';
  width: 100%;
`;

const H2asH3 = H3.withComponent('h2');

const HomeFeatures = () => (
  <FullHeightTable backgroundColor={colors.white}>
    <InnerContent>
      <CappedWidth>
        <InnerWrapper>
          <GridTopCol>
            <H4Text color={colors.gray} align="center">
              Other features that our clients love about Cogo
            </H4Text>
          </GridTopCol>
          <BenefitItems>
            <BenefitItem
              columns={4}
              titleComponent={H2asH3}
              imagePath={cogoPresentationPath}
              title="Presentation Mode"
              titleColor={colors.green}
              description={
                <span>
                  When you are in a meeting, you can flip to presentation mode
                  with just <Highlight>one click</Highlight>. Presentation mode
                  shows a focussed view of just the content and hides everything
                  that is not relevant.
                </span>
              }
            />
            <BenefitItem
              columns={4}
              titleComponent={H2asH3}
              imagePath={cogoVersioningPath}
              title="Version Control"
              titleColor={colors.green}
              description={
                <span>
                  All files and links that are added as attachments within Cogo
                  are <Highlight>version controlled</Highlight>. This means that
                  you can always easily go back to a previous version. For a
                  weekly PDF report you can go back in time and look at older
                  versions.
                </span>
              }
            />
          </BenefitItems>
          <BenefitItems>
            <BenefitItem
              columns={4}
              titleComponent={H2asH3}
              imagePath={cogoNoMoreFolders}
              title="Collections"
              titleColor={colors.green}
              description={
                <span>
                  Cogo has a powerfull concept called collections. With
                  collections you can group reports. You can mix different
                  technologies (Tableau, PowerBI, etc...) and you can put a
                  report in <Highlight>multiple collections</Highlight> without
                  needing to copy the report.
                </span>
              }
            />
            <BenefitItem
              columns={4}
              titleComponent={H2asH3}
              imagePath={cogoAnnouncements}
              title="Announcements"
              titleColor={colors.green}
              description={
                <span>
                  To announce visitors of the portal about certain new
                  developments or upcoming maintenance, Cogo provides
                  announcements that are highlighed on the{' '}
                  <Highlight>home page</Highlight> of the portal for every
                  visitor.
                </span>
              }
            />
          </BenefitItems>
        </InnerWrapper>
        <GridTopCol>
          <H4Text color={colors.gray}>
            Integrates with most BI-Solutions on the market
          </H4Text>
        </GridTopCol>
        <GridTopCol
          padded
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <IntegrationsImg
            src={tableauLogoPath}
            alt="Cogo Integrates with Tableau"
          />
          <IntegrationsImg
            src={powerbiLogoPath}
            alt="Cogo Integrates with PowerBI"
          />
          <IntegrationsImg
            src={goodDataLogoPath}
            alt="Cogo Integrates with GoodData"
          />
          <IntegrationsImg src={qlikLogo} alt="Cogo Integrates with Qlik" />
          <IntegrationsImg
            src={microstrategyLogo}
            alt="Cogo Integrates with MicroStrategy"
          />
          <IntegrationsImg src={lookerLogo} alt="Cogo Integrates with Looker" />
          <IntegrationsImg
            src={sapLogo}
            alt="Cogo Integrates with SAP Business Objects"
          />
        </GridTopCol>
        <GridTopCol>
          <H4Text color={colors.gray}>Other integrations</H4Text>
        </GridTopCol>
        <GridTopCol
          padded
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <IntegrationsImg
            src={slackLogoPath}
            alt="Cogo integrates with Slack"
          />
        </GridTopCol>
        {/* <GridTopCol>
          <OutlineButton to="/product">See all features ></OutlineButton>
        </GridTopCol> */}
      </CappedWidth>
    </InnerContent>
  </FullHeightTable>
);

export default HomeFeatures;
