import React from 'react';
import { colors } from '../../components/styles';
import { H2, H4Text, Paragraph } from '../../components/Typography';
import Companies from '../../components/Companies';
import EmailForm from '../../components/EmailForm';
import { GridTopCol } from '../../components/grid/Item';
import { FullHeightTable, InnerContent } from './common';
import OutlineButton from '../OutlineButton';

const HomeCTA = () => (
  <FullHeightTable backgroundColor="transparent">
    <InnerContent>
      <GridTopCol>
        <H2 color={colors.white} align="center">
          Learn more on how to adopt Cogo in your organization.
        </H2>
        {/* <Paragraph color={colors.white} align="center">
          Get your team started with up to 50 items (and 5GB of storage). Free
          forever. No credit card needed.
        </Paragraph> */}
        <OutlineButton href="mailto:enterprise@cogo.ai" white>
          Schedule a demo
        </OutlineButton>
        {/* <EmailForm align="center" /> */}
      </GridTopCol>
      <Companies isWhite>
        <H4Text color={colors.white} align="center">
          Trusted by
        </H4Text>
      </Companies>
    </InnerContent>
  </FullHeightTable>
);

export default HomeCTA;
