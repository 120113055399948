import React from 'react';
import styled from 'react-emotion';
import { ResponsiveImg } from '../components/Images';

const IconWrapper = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  margin-top: 0px;
  clear: both;
  padding-top: 20px;
`;

const FeaturesIconOne = styled(IconWrapper)`
  @media (min-width: 320px) {
    width: calc((1.5/12)*100%);
    margin-right: calc((0.5/12)*100%);
  }
  @media (min-width: 500px) {
    width: calc((1.5/12)*100%);
    margin-right: calc((0.5/12)*100%);
  }
  @media (min-width: 690px) {
    width: calc((2/12)*100%);
  }
  @media (min-width: 810px) {
    width: calc((1.5/12)*100%);
  }
  @media (min-width: 970px) {
    width: calc((3/12)*100%);
  }
  @media (min-width: 1140px) {
    width: calc((3/12)*100%);
  }
`;

const FeaturesIconThree = styled(IconWrapper)`
  @media (min-width: 320px) {
    width: calc((1.5/12)*100%);
    margin-right: calc((0.5/12)*100%);
  }
  @media (min-width: 690px) {
    width: calc((1/12)*100%);
  }
  @media (min-width: 810px) {
    width: calc((2.5/12)*100%);
  }
`;

const FeatureIcons = ({ imagePath, columns, alt = '' }) => {
  let Base;
  switch (columns) {
    case 3:
      Base = FeaturesIconThree;
      break;
    default:
      Base = FeaturesIconOne;
      break;
  }

  return (
    <Base>
      <ResponsiveImg src={imagePath} alt={alt} />
    </Base>
  );
};

export default FeatureIcons;
