import React from 'react';
import styled from 'react-emotion';
import Item from '../components/grid/Item';
import FeaturesCol from '../components/FeaturesCol';
import FeaturesIcon from './FeaturesIcon';
import FeatureList from './FeatureList';

const BenefitItem = ({ columns = 4, imagePath, ...otherProps }) => (
  <FeaturesCol columns={columns}>
    <FeaturesIcon imagePath={imagePath} alt={`Cogo ${otherProps.title}`} />
    <FeatureList {...otherProps} />
  </FeaturesCol>
);

export const BenefitItems = styled(Item)`
  float: left;
  text-align: left;
  width: 100%;

  @media (min-width: 810px) {
    width: 50%;
  }
  @media (min-width: 970px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export default BenefitItem;
