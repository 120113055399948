import React from 'react';
import styled from 'react-emotion';

import FeaturesIcon from './FeaturesIcon';
import Item from './grid/Item';
import FeatureList from './FeatureList';
import { colors } from './styles';

const ThreeColWrapper = styled(Item)`
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  @media (min-width: 810px) {
    padding: 20px;
    width: calc((4/12)*100%);
  }
`;
export default ({ icon, alt, title, children, dark = false }) => (
  <ThreeColWrapper>
    <FeaturesIcon imagePath={icon} columns={3} alt={alt || title} />
    <FeatureList
      isShort
      columns={3}
      titleColor={dark ? colors.silver : colors.bluegray}
      descriptionColor={dark ? colors.white : colors.bluegray}
      title={title}
      description={children}
    />
  </ThreeColWrapper>
);
