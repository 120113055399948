import React from 'react';
import styled from 'react-emotion';
import Item from '../components/grid/Item';

const Column = styled(Item)`
  width: 100%;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  float: left;
`;

const TwoColumns = styled(Column)`
  height: 100%;
  @media (min-width: 320px) {
    text-align: center;
  }

  @media (min-width: 810px) {
    width: 50%;
    text-align: left;
  }
`;

const FourColumns = styled(Column)`
  @media (min-width: 690px) {
    width: 50%;
    padding: 10px;
  }
  @media (min-width: 810px) {
    width: 100%;
  }
  @media (min-width: 970px) {
    width: 50%;
    padding: 20px;
  }
`;

const FiveColumns = styled(Column)`
  @media (min-width: 690px) {
    width: calc((4/12)*100%);
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: 810px) {
    width: calc((4/12)*100%);
    padding: 10px;
  }
  @media (min-width: 970px) {
    width: calc((2.4/12)*100%);
    padding: 0px;
  }
`;

const FeaturesCol = ({ columns, children, ...otherProps }) => {
  switch (columns) {
    case 2:
      return <TwoColumns {...otherProps}>{children}</TwoColumns>;
    case 4:
      return <FourColumns {...otherProps}>{children}</FourColumns>;
    case 5:
      return <FiveColumns {...otherProps}>{children}</FiveColumns>;

    default:
      return null;
  }
};

export default FeaturesCol;
