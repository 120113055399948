import React, { Component } from 'react';
import styled from 'react-emotion';
import { GridTopCol } from '../grid/Item';

const TabsContainer = styled.div`
  width: 100%;
  @media (min-width: 320px) {
    width: 66.66%;
    margin-left: calc((2/12)*100%);
  }
  @media (min-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
`;

const TabList = ({
  activeIndex,
  onActive,
  wrapperClassName = '',
  ...props
}) => (
  <GridTopCol className={wrapperClassName}>
    <TabsContainer {...props}>
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          isActive: index === activeIndex,
          onActivate: () => {
            onActive(index);
          },
        })
      )}
    </TabsContainer>
  </GridTopCol>
);

export default TabList;
