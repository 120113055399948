import React, { Component } from 'react';
import TabPanels from './TabPanels';
import TabList from './TabList';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.initialActiveIndex || 0,
    };
  }

  render() {
    const { activeIndex } = this.state;

    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        activeIndex,
        onActive: (newIndex) => {
          this.setState({ activeIndex: newIndex });
        },
      }));
  }
}

export default Tabs;
