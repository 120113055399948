import React from 'react';
import styled, { css } from 'react-emotion';

import cogoRecoverFlatPath from '../../images/cogo_recover_flat.gif';
import cogoPublishFlatPath from '../../images/cogo_publish_flat.gif';
import cogoDiscoverFlatPath from '../../images/cogo_discover_flat.gif';
import cogoShareFlat from '../../images/cogo_share_flat.gif';
import Link from '../../components/Link';
import { colors } from '../../components/styles';
import { H2, H3Text, H4, Blockquote } from '../../components/Typography';
import FeaturesCol from '../../components/FeaturesCol';
import TabPanel from '../../components/Tabs/TabPanel';
import TabList from '../../components/Tabs/TabList';
import Tabs from '../../components/Tabs/Tabs';
import Tab from '../../components/Tabs/Tab';
import TabPanels from '../../components/Tabs/TabPanels';
import { FlatlayImg } from '../../components/Images';
import CappedWidth from '../../components/CappedWidth';
import { GridTopCol } from '../../components/grid/Item';
import { FullHeightTable, InnerContent } from './common';

const ModifiedTabList = styled(TabList)`
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
`;

const tabListWrapperClass = css`
  @media (max-width: 375px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const HomeWork = () => (
  <FullHeightTable backgroundColor={colors.silver}>
    <InnerContent>
      <CappedWidth>
        <GridTopCol>
          <H4 color={colors.gray} align="center">
            The analytics hub for your organization
          </H4>
        </GridTopCol>
        <div
          css={{
            paddingTop: 100,
            marginBottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <H2 color={colors.gray}>Integrate & Sync</H2>
          <Blockquote color={colors.gray} css={{ marginBottom: '3em' }}>
            Cogo syncs and integrates with your BI-solutions such as tableau.
            This means that your dashboards or reports are available in Cogo
            with a single click (once configured). Cogo retains the permissions
            as configured in the BI-solution, so every user can only see the
            reports and dashboards that they have access to. When dashboards are
            updated they are automatically updated in Cogo.
          </Blockquote>
          {/* <Link to="/product">Learn more about publishing ></Link> */}
          <H2 color={colors.gray}>Search & Discover</H2>
          <Blockquote color={colors.gray} css={{ marginBottom: '3em' }}>
            Cogo searches through all metadata and file contents while you are
            typing, giving instant results. Even if a term is mentioned in a
            PDF-report, it will surface with Cogo. The sophisticated search
            engine will also make sure the most relevant results will be listed
            first.
          </Blockquote>
          {/* <Link to="/product">Learn more about discovery ></Link> */}
          <H2 color={colors.gray}>Social & Communication</H2>
          <Blockquote color={colors.gray} css={{ marginBottom: '3em' }}>
            Cogo is not just a catalog for your reports. It also offers social
            and communication functionalities. Users can personalize Cogo by
            favoriting reports, so they are always at their fingertips. When
            they have questions about a certain report they can ask a questions
            in the realtime comments. And you can look at a user's profile to
            see her contributions.
          </Blockquote>
          {/* <Link to="/product">Learn more about locate ></Link> */}
          <H2 color={colors.gray}>Enterprise Ready</H2>
          <Blockquote color={colors.gray} css={{ marginBottom: '3em' }}>
            Cogo is highly customizable. You can easily configure it so matches
            your organization's look and feel. It also offers bank-level data
            security, single sign-on and virtual private cloud deployments on
            Amazon Web Services.
          </Blockquote>
          {/* <Link to="/product">Learn more about sharing ></Link> */}
        </div>
      </CappedWidth>
    </InnerContent>
  </FullHeightTable>
);

export default HomeWork;
