import React from 'react';
import styled from 'react-emotion';
import { colors } from '../../components/styles';

export const FullHeightTable = styled.div`
  width: 100%;
  height: 100vh;
  display: table;
  background: ${({ backgroundColor = colors.bluegray }) => backgroundColor};
`;

export const InnerContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: 80vh;
`;
