import React from 'react';
import styled from 'react-emotion';
import Item from '../../components/grid/Item';

const TabPanelsBase = styled(Item)`
  width: 100%;
  float: left;
  margin-bottom: 20px;
  padding-bottom: ${({ padded }) => (padded ? 40 : 0)}px;
`;

const TabPanels = ({ children, activeIndex, padded }) => (
  <TabPanelsBase padded={padded}>{children[activeIndex]}</TabPanelsBase>
);

export default TabPanels;
