import React from 'react';
import styled from 'react-emotion';
import { H3, Paragraph } from './Typography';
import Item from './grid/Item';
import { colors } from './styles';

export const ThreeColumns = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  margin-top: 0px;
  @media (min-width: 320px) {
    width: calc((10/12)*100%);
    padding-bottom: 20px;
  }
  @media (min-width: 690px) {
    width: calc((9/12)*100%);
  }
  @media (min-width: 810px) {
    width: 100%;
  }
`;

export const DefaultWrapper = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  margin-top: 0px;
  @media (min-width: 320px) {
    width: calc((10/12)*100%);
    padding-bottom: 20px;
  }
  @media (min-width: 500px) {
    min-height: 8em;
    margin-bottom: 0px;
  }
  @media (min-width: 690px) {
    min-height: 16em;
  }
  @media (min-width: 810px) {
    min-height: 15em;
  }
  @media (min-width: 970px) {
    width: calc((12/12)*100%);
    min-height: 10em;
  }
  @media (min-width: 1140px) {
    margin-right: calc((0/12)*100%);
    min-height: 12em;
  }
`;

export const FeatureContainer = styled(Item)`
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  @media (min-width: 690px) {
    width: 50%;
    padding: 10px;
  }
  @media (min-width: 970px) {
    padding: 20px;
  }
`;

export const ShortThreeColumns = styled.div`
  width: 100%;
  display: inline-block;
  float: left;
  margin-top: 0px;
  @media (min-width: 320px) {
    width: calc((10/12)*100%);
  }
  @media (min-width: 810px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

const FeatureList = ({
  title,
  description,
  titleColor = colors.gray,
  descriptionColor = colors.gray,
  titleComponent: TitleComponent = H3,
  columns,
  isShort,
  children,
}) => {
  let BaseComponent;
  switch (columns) {
    case 3:
      BaseComponent = isShort ? ShortThreeColumns : ThreeColumns;
      break;
    default:
      BaseComponent = DefaultWrapper;
      break;
  }

  return (
    <BaseComponent>
      <TitleComponent color={titleColor} align="left">
        {title}
      </TitleComponent>
      <Paragraph color={descriptionColor}>{children || description}</Paragraph>
    </BaseComponent>
  );
};

export default FeatureList;
