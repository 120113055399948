import React from 'react';
import styled from 'react-emotion';
import { H5Text } from '../../components/Typography';
import { colors } from '../../components/styles';

const TabBase = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
  margin: 0 auto;
  @media (min-width: 320px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (min-width: 810px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export default ({
  children, onActivate, isActive, component: Component = H5Text,
}) => {
  const DecoratedComponent = styled(Component)`
    cursor: pointer;
    color: ${({ highlight }) => (highlight ? colors.blue : colors.concrete)};
    min-height: 2em;
    padding-bottom: ${({ highlight }) => (highlight ? 10 : 0)}px;
    border-bottom: ${({ highlight }) =>
    (highlight ? `2px solid ${colors.blue}` : '2px solid transparent')};

    &:hover {
      padding-bottom: 10px;
      color: ${colors.blue};
      border-bottom: 2px solid ${colors.blue};
    }
  `;
  return (
    <TabBase>
      <DecoratedComponent highlight={isActive} onClick={onActivate}>
        {children}
      </DecoratedComponent>
    </TabBase>
  );
};
