import React from 'react';
import Footer from '../components/Footer';

import HomeCTA from '../components/HomePage/HomeCTA';
import HomeWhy from '../components/HomePage/HomeWhy';
import HomeWork from '../components/HomePage/HomeWork';
import HomeDeserveBetter from '../components/HomePage/HomeDeserveBetter';
import HomeFeatures from '../components/HomePage/HomeFeatures';
import Contents from '../components/Contents';
import FullHeader from '../components/FullHeader';
import Layout from '../components/Layout';

const HomePage = () => (
  <Layout>
    <FullHeader
      title="Cogo, the Analytics Hub for your company."
      isFull
      enterpriseCta
      isEnterprise
    >
      Bringing together all BI-solutions, documentation and communication in a
      great user experience
    </FullHeader>
    <Contents>
      <HomeDeserveBetter />
      <HomeWork />
      <HomeFeatures />
      <HomeWhy />
      <HomeCTA />
    </Contents>
    <Footer />
  </Layout>
);

export default HomePage;
