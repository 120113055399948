import React from 'react';
import styled from 'react-emotion';
import { colors } from '../../components/styles';
import { H2, Blockquote } from '../../components/Typography';
import cogoIllustrationHomeFull from '../../images/cogo_illustration_home_full_2.svg';
import cogoSearchFilePath from '../../images/cogo_spark_growth.png';
import Item from '../../components/grid/Item';

const ContainerWithBgImage = styled.div`
  background-size: contain;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: table;
  height: 100vh;
  background-image: url(${cogoIllustrationHomeFull});
  background-color: ${colors.bluegray};
  background-size: 125%;
  background-attachment: scroll;
  @media (min-width: 500px) {
  }
  @media (min-width: 690px) {
    background-size: 125%;
  }
  @media (min-width: 810px) {
    background-size: 150%;
  }
  @media (min-width: 970px) {
    background-size: 125%;
    background-attachment: scroll;
  }
  @media (min-width: 1140px) {
    background-size: cover;
    background-attachment: fixed;
  }
  @media (min-width: 1490px) {
    /* background-size: 125%; */
    /* height: auto; */
  }
  background-color: ${colors.bluegray};
`;

const InnerContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  background: radial-gradient(circle, ${colors.bluegray} 36%, transparent 100%);
  background: radial-gradient(circle, ${colors.bluegray} 65%, transparent 100%);
  @media (min-width: 810px) {
    background: radial-gradient(
      circle,
      ${colors.bluegray} 50%,
      transparent 100%
    );
  }
  @media (min-width: 970px) {
    background: radial-gradient(
      circle,
      ${colors.bluegray} 36%,
      transparent 100%
    );
  }
`;

const ProductWrapper = styled(Item)`
  width: 100%;
  float: left;
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;
  width: calc((12/12)*100%);
  float: left;
  margin: 0 auto;
  @media (min-width: 500px) {
    width: calc((10/12)*100%);
    float: left;
    margin: 0 auto;
    margin-left: calc((1/12)*100%);
  }
  @media (min-width: 800px) {
    width: calc((8/12)*100%);
    float: left;
    margin: 0 auto;
    margin-left: calc((2/12)*100%);
  }
  @media (min-width: 1140px) {
    width: calc((6/12)*100%);
    float: left;
    margin: 0 auto;
    margin-left: calc((3/12)*100%);
  }
`;

const HomeDeserveBetter = () => (
  <ContainerWithBgImage>
    <InnerContent>
      <ProductWrapper>
        <img
          src={cogoSearchFilePath}
          style={{ width: '6em', paddingTop: 40 }}
          alt="Driving adoption"
        />
        <H2 color={colors.white}>
          Do you have trouble driving the adoption BI in your organization??
        </H2>
        <Blockquote
          color={colors.white}
          align="left"
          css={{ marginBlockStart: '2em', marginBlockEnd: '2em' }}
        >
          Most companies nowadays use more than three different BI-solutions.
          You are probably one of them. We use multiple solutions because there
          are more solutions on the market today, and every team needs different
          capabilities.
        </Blockquote>
        <Blockquote
          color={colors.white}
          align="left"
          css={{ marginBlockStart: '2em', marginBlockEnd: '2em' }}
        >
          Some solutions are really good at stable and consistent reporting of
          well known KPI's (Mode 1) and others good at data discovery and
          exploration (Mode 2).
        </Blockquote>
        <Blockquote
          color={colors.white}
          align="left"
          css={{ marginBlockStart: '2em', marginBlockEnd: '2em' }}
        >
          However driving adoption and governing multiple solutions is not easy.
          Cogo creates a beautiful user experience on top of all your
          BI-solutions with advanced integrations and using embedded analytics.
          Your users can use and discover all analytics your organization has to
          offer all within Cogo. They don't even have to know what solutions are
          used in the background.
        </Blockquote>
      </ProductWrapper>
    </InnerContent>
  </ContainerWithBgImage>
);

export default HomeDeserveBetter;
