import React from 'react';
import styled from 'react-emotion';
import { colors } from '../../components/styles';
import { H2 } from '../../components/Typography';
import { GridTopCol } from '../../components/grid/Item';
import CappedWidth from '../../components/CappedWidth';
import OutlineButton from '../../components/OutlineButton';
import { FullHeightTable, InnerContent } from './common';
import Feature from '../Feature';
import superchargeMeetingsIcon from '../../images/cogo_supercharge_meetings.png';
import sparkGrowthIcon from '../../images/cogo_spark_growth.png';
import billingIcon from '../../images/cogo_billing.png';
import beatSilosIcon from '../../images/cogo_beat_silos.png';

const InnerWrapper = styled.div`
  display: table;
  clear: both;
  content: '';
  width: 100%;
`;

export default () => (
  <FullHeightTable backgroundColor={colors.bluegray}>
    <InnerContent>
      <CappedWidth>
        <InnerWrapper>
          <GridTopCol>
            <H2 color={colors.white}>The missing link in your BI-stack</H2>
          </GridTopCol>

          <Feature dark title="Beat company silos" icon={beatSilosIcon}>
            Beat company silos and make BI accessible for everyone in your
            organization. Users can now have a full overview of what
            BI/Analytics is available to them.
          </Feature>
          <Feature dark title="Increase the ROI of BI" icon={billingIcon}>
            BI adoption increases the ROI of BI and increases the objective of
            becoming an insight driven organization.
          </Feature>
          <Feature
            dark
            title="Increase Effectiveness"
            icon={superchargeMeetingsIcon}
          >
            With Cogo it's easier than ever to adopt new BI-solutions, and make
            them available in a single place. Users don't need to learn anything
            new to use new solutions, they just pop up in Cogo. Greatly
            increasing the effectiveness of adopting new BI-Solutions.
          </Feature>

          {/* <GridTopCol>
            <OutlineButton white href="mailto:hello@cogo.ai">
              Hear what our customers say >
            </OutlineButton>
          </GridTopCol> */}
        </InnerWrapper>
      </CappedWidth>
    </InnerContent>
  </FullHeightTable>
);
